.board {
  position:fixed;
  width:100%;
  height:100%;
  top:0;
  left:0;
  display:flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-size:30px;
  user-select: none;
  .logo {
    width:min(500px,80%);
    animation: 1s fade;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    svg {
      width:100%;
      height:100%;
    }
  }

}
.splashscreen-mask {
  position:absolute;
  width:100%;
  height:100%;
  top:0;
  left:0;
  background: #FFF;
  opacity:1;
  transition:opacity 0.2s;
  &.started {
    opacity:0;
  }
}

@keyframes fade {
  0% { opacity:0;width:min(250px,40%); }
  20% { opacity:0;width:min(250px,40%); }
  100% { opacity:1;width:min(500px,80%); }
}
