.input {
  width:100%;
  min-height:100%;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .form {
    width:min(300px,90%);
    flex-grow: 1;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .action {
    &>div {
      width:100%;
    }
    .MuiCheckbox-root {
      height:2.5em;
      width:2.5em;
    }
    .terms {
      text-align:left;
      font-size:0.8em;
      user-select: none;
    }
    .optin {
      text-align:left;
      font-size:0.8em;
      user-select: none;
      .more-btn {
        color:#000;
        text-decoration:underline;
        cursor:pointer;
      }
    }
  }
}
