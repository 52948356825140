.wait {
  width:100%;
  min-height:100%;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .please-wait {
    margin-bottom:15px;
  }
  .rank {
    font-family: 'century_gothicregular';
  }
}
