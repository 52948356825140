.playing {
  width:100%;
  min-height:100%;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .btn {
    max-width:300px;
    text-align:center;
    margin:30px 0;
  }

}
