.home {
  width:100%;
  min-height:100%;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .logo {
    width:min(200px,90%);
    height:20vh;
    margin-top: 20vh;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    svg {
      width:100%;
      height:100%;
    }
  }
  .text {
    flex-grow: 1;
    max-width:300px;
    text-align:center;
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    margin:30px 0;
  }
  .btn {
    max-width:300px;
    text-align:center;
    margin:30px 0;
  }
}
