.start {
  width:100%;
  min-height:100%;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .drawing {
    width:min(180px,90%);
    margin:10px 0;
    img {
      width:100%;
    }
    flex-grow: 1;
  }
  .text {
    width:min(300px,90%);
    font-family: 'century_gothicregular';
    text-align:center;
    font-size:1em;
  }
  .question {
    width:min(300px,90%);
    font-family: 'century_gothicregular';
    text-align:center;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size:1em;
    flex-grow: 1;
  }
  .question-btns {
    width:90%;
    display:flex;
    justify-content: center;
    .btn {
      font-family: 'hennessy_sansregular';
      min-width: 100px;
      text-align:center;
      margin:10px;
    }
  }
}
